import logoLink from '../resources/quickline.svg';

export const cssVariables = {
    primary_light: '#FFCCCC',
    primary: '#D40037',
    primary_dark: '#470012',
    text_on_primary: '#fff',
    primary_transparent: '#FDF2F5',

    secondary: '#000',
    secondary_light: '#e6e6e6',
    text_on_secondary: 'white',
    secondary_transparent: '#FAFAFA',

    font_color: 'black',
    link_color: '#666666',

    font: 'Europa, Arial, Helvetica, sans-serif',

    border_width: '1px',
    border_style: 'solid',

    active: '#CCFFCC',
    inactive: '#FFCCCC',

    edit_color: '#D40037',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#000',    

    dropdown_background: '#FFCCCC',
    toggle_switch: 'rgb(120, 120, 120)',
};

export const logo = logoLink;
